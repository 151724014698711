import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          <path d="M5125 10164 c-22 -2 -96 -9 -165 -15 -550 -47 -1142 -214 -1665 -469
-520 -255 -975 -595 -1401 -1050 -597 -638 -1023 -1489 -1184 -2365 -123 -671
-95 -1423 76 -2065 186 -695 498 -1299 963 -1860 116 -140 362 -393 491 -503
52 -45 104 -90 115 -101 11 -10 56 -45 100 -79 44 -33 82 -64 85 -67 7 -9 162
-118 250 -177 195 -130 546 -314 805 -421 370 -154 790 -264 1207 -317 831
-106 1631 -1 2423 317 348 140 832 417 1099 630 227 180 392 330 581 529 295
309 553 678 759 1084 112 219 121 239 196 430 89 226 179 520 220 720 139 680
146 1270 24 1915 -96 509 -323 1111 -569 1511 -5 9 -19 32 -29 50 -83 148
-306 458 -446 620 -298 344 -525 552 -906 828 -68 49 -192 131 -199 131 -4 0
-21 11 -38 24 -18 13 -52 35 -77 48 -25 14 -96 53 -158 87 -510 281 -1126 472
-1766 547 -123 14 -695 28 -791 18z m645 -69 c392 -32 751 -103 1112 -221 148
-48 173 -57 323 -119 492 -204 912 -462 1300 -799 153 -133 379 -359 481 -481
32 -38 64 -76 71 -83 8 -8 26 -30 41 -50 15 -21 46 -61 70 -91 38 -47 75 -99
107 -150 6 -9 13 -18 16 -21 7 -5 137 -202 155 -235 6 -11 34 -60 63 -110 94
-162 221 -425 294 -610 208 -530 327 -1156 327 -1725 0 -151 -22 -474 -40
-601 -54 -375 -122 -656 -235 -974 -99 -278 -198 -493 -350 -760 -326 -575
-800 -1097 -1357 -1498 -573 -411 -1271 -696 -1983 -811 -1506 -241 -3026 251
-4097 1327 -455 456 -803 985 -1036 1572 -180 453 -270 832 -323 1360 -18 181
-16 618 5 820 52 517 169 977 368 1440 32 75 157 330 186 380 9 17 34 60 55
97 21 37 68 114 105 172 168 260 256 377 487 642 142 164 452 444 675 610 692
517 1552 845 2400 914 69 6 143 12 165 13 113 10 461 5 615 -8z"/>
          <path d="M4610 9119 c-362 -24 -653 -157 -848 -389 -81 -96 -92 -112 -91 -130
0 -13 1 -13 6 0 11 27 156 160 227 207 151 100 292 153 511 193 96 18 368 24
490 11 194 -21 426 -89 537 -158 21 -13 41 -23 44 -23 8 0 105 -60 114 -70 3
-3 25 -21 50 -40 67 -51 136 -117 199 -191 72 -85 91 -108 91 -113 0 -2 18
-34 41 -71 36 -60 67 -121 112 -225 42 -97 107 -356 107 -428 0 -16 9 -41 21
-56 18 -23 28 -26 77 -26 77 0 127 16 152 47 30 38 27 63 -9 63 -15 0 -62 17
-102 38 -81 41 -73 27 -98 167 -12 69 -46 194 -65 240 -9 20 -16 46 -16 56 0
11 -4 19 -9 19 -5 0 -12 12 -16 28 -8 34 -81 182 -101 203 -8 8 -14 21 -14 27
0 7 -4 12 -10 12 -5 0 -10 6 -10 14 0 8 -4 16 -8 18 -4 2 -28 30 -54 63 -54
71 -160 173 -243 235 -33 25 -62 47 -65 50 -20 20 -167 94 -260 132 -205 82
-471 116 -760 97z"/>
          <path d="M2962 8233 c-31 -81 -68 -218 -98 -363 -28 -134 -26 -552 4 -715 45
-245 100 -420 176 -557 14 -27 38 -69 52 -94 156 -285 424 -540 899 -859 365
-244 701 -445 747 -445 5 0 46 -19 91 -42 62 -32 103 -64 172 -133 50 -50 127
-118 170 -150 44 -32 82 -61 85 -64 3 -3 32 -26 65 -51 33 -24 65 -49 71 -55
6 -5 40 -34 75 -64 368 -314 561 -671 560 -1039 0 -67 -3 -144 -6 -170 -12
-93 -40 -227 -48 -233 -5 -4 -7 -15 -5 -25 5 -26 55 105 86 225 30 120 41 352
22 469 -36 224 -134 435 -295 632 -55 67 -298 300 -314 300 -5 0 -11 4 -13 8
-1 5 -41 38 -88 73 -47 35 -87 66 -90 70 -3 3 -27 21 -55 39 -27 19 -59 42
-69 52 -11 10 -23 18 -28 18 -4 0 -8 5 -8 10 0 11 -107 121 -149 153 -31 25
-181 97 -200 97 -15 0 -102 39 -111 50 -5 6 -57 37 -130 78 -25 13 -59 35 -77
48 -17 13 -36 24 -42 24 -6 0 -11 5 -11 10 0 6 -5 10 -10 10 -11 0 -266 164
-370 238 -245 173 -290 206 -290 213 0 5 -5 9 -11 9 -6 0 -34 19 -63 43 -28
23 -64 53 -81 67 -58 48 -210 207 -261 272 -93 119 -136 186 -219 343 -95 180
-164 438 -191 717 -17 179 -17 196 0 379 16 181 37 291 83 432 25 79 8 64 -25
-20z"/>
          <path d="M4246 7335 c4 -15 2 -25 -5 -25 -6 0 -11 5 -11 10 0 19 -16 10 -34
-21 -41 -69 0 -125 190 -264 60 -43 111 -82 114 -86 6 -7 354 -238 385 -255
11 -6 52 -31 90 -56 39 -25 86 -53 105 -63 19 -10 37 -21 40 -24 21 -26 181
-93 197 -83 5 4 21 30 36 57 23 45 26 63 30 185 2 74 7 142 11 151 19 43 31
44 681 47 636 3 871 -5 985 -31 36 -9 91 -21 122 -27 70 -14 215 -83 287 -138
65 -49 165 -159 208 -227 71 -113 95 -167 168 -374 59 -165 63 -171 124 -171
93 0 106 7 140 70 36 68 38 94 21 430 -10 210 -25 525 -36 778 l-7 142 -1924
0 -1923 0 6 -25z"/>
          <path d="M5260 6270 c11 -21 76 -53 94 -47 11 4 -80 67 -96 67 -5 0 -4 -9 2
-20z"/>
          <path d="M7678 5438 c-24 -39 -37 -80 -53 -174 -8 -49 -22 -114 -31 -143 -25
-85 -10 -83 23 4 27 73 46 145 72 280 11 58 9 64 -11 33z"/>
          <path d="M4495 5042 l-30 -57 -3 -1217 -2 -1218 -38 0 c-86 0 -302 65 -437
132 -69 34 -209 120 -225 138 -3 3 -30 24 -60 46 -114 86 -246 230 -337 365
-152 228 -229 468 -254 789 -6 80 -15 150 -20 155 -4 6 -53 11 -108 12 l-99 2
-37 -61 -36 -61 0 -915 c0 -776 2 -917 14 -930 18 -17 119 -26 172 -15 35 7
46 17 82 73 85 129 96 142 137 161 52 23 92 24 186 0 82 -20 347 -105 395
-127 17 -7 57 -21 90 -30 33 -9 94 -27 135 -40 72 -22 136 -37 305 -70 64 -13
85 -22 108 -45 l27 -29 0 -199 0 -198 -31 -7 c-17 -3 -111 -6 -208 -6 -97 0
-181 -4 -187 -8 -6 -4 -25 -36 -42 -71 -27 -53 -32 -76 -32 -133 0 -120 -94
-108 883 -107 463 1 853 4 866 8 16 4 36 27 58 66 29 53 33 69 33 133 0 120
17 112 -254 112 -202 0 -235 2 -240 16 -8 20 -7 2392 0 2471 l7 63 36 5 c101
17 103 18 137 75 l33 55 -62 62 c-60 61 -89 86 -257 220 -116 92 -416 302
-597 416 -61 38 -73 35 -108 -31z"/>
          <path d="M7560 5024 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
          <path d="M6880 4303 c0 -60 4 -63 98 -63 179 0 362 -64 460 -159 130 -127 177
-242 202 -491 6 -63 18 -125 25 -136 18 -28 69 -37 126 -23 38 10 50 19 63 46
9 19 16 38 16 43 0 5 -34 9 -77 7 l-78 -2 -3 35 c-11 149 -38 335 -58 394 -49
144 -151 261 -281 323 -87 42 -187 61 -360 70 l-133 6 0 -50z"/>
          <path d="M6976 3295 c-128 -35 -140 -157 -18 -184 26 -5 86 -13 134 -17 100
-8 118 -15 99 -38 -18 -21 -138 -22 -165 -1 -13 10 -43 15 -83 15 -60 0 -63
-1 -63 -24 0 -31 49 -73 98 -83 20 -4 82 -8 137 -7 127 1 188 24 211 79 18 44
12 68 -24 94 -29 20 -146 41 -232 41 -54 0 -71 14 -46 38 23 23 123 23 159 -1
18 -11 46 -17 87 -17 52 0 60 3 60 19 0 34 -39 70 -92 86 -63 18 -195 18 -262
0z"/>
          <path d="M7371 3176 l4 -125 33 -34 c44 -45 104 -62 212 -61 94 1 144 19 190
69 23 25 25 34 28 151 l4 124 -66 0 -66 0 0 -95 c0 -88 -2 -98 -25 -120 -19
-20 -34 -25 -72 -25 -101 0 -113 16 -113 150 l0 90 -66 0 -66 0 3 -124z"/>
          <path d="M7900 3130 l0 -170 189 0 c104 0 201 4 216 10 30 12 59 71 49 102 -3
12 -17 31 -31 44 -23 22 -24 24 -9 46 19 26 21 61 5 90 -21 39 -67 48 -249 48
l-170 0 0 -170z m305 80 c16 -25 -18 -40 -90 -40 -51 0 -76 4 -85 15 -22 26 0
35 85 35 47 0 87 -5 90 -10z m23 -147 c3 -23 1 -23 -96 -23 -101 0 -116 6
-104 38 3 9 29 12 101 10 91 -3 96 -4 99 -25z"/>
          <path d="M6516 3034 c-9 -8 -16 -19 -16 -23 0 -4 -12 -21 -27 -38 -16 -17 -54
-62 -85 -100 -31 -37 -85 -93 -118 -123 -34 -30 -74 -66 -89 -80 -31 -30 -110
-89 -205 -154 -99 -69 -324 -178 -359 -174 l-28 3 -2 168 c0 92 -2 167 -3 167
-1 0 -13 -17 -26 -37 -21 -35 -23 -52 -26 -188 -2 -94 0 -161 7 -179 11 -27
14 -28 58 -22 54 7 193 76 313 154 47 31 87 59 90 62 3 3 21 17 40 30 57 40
299 273 345 332 105 134 163 218 151 218 -2 0 -12 -7 -20 -16z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
